<template>
<b-row class="h-100">
    <b-colxx xxs="12" md="10" class="mx-auto my-auto">
        <b-card class="auth-card" no-body>
            <div class="position-relative image-side">
                <p class="text-white h2">{{ $t('dashboards.magic-is-in-the-details') }}</p>
                <p class="white mb-0">
                    Si no recibiste el correo de verificación ingresa tu dirección para el reenvío
                    <br />Si aun no es miembro favor de 
                    <router-link tag="a" to="/register" class="white">registrarse</router-link>
                </p>
            </div>
            <div class="form-side">
                <router-link tag="a" to="/">
                    <img class="customLogo loginLogo" src="/static/img/logo-sole.svg">
                </router-link>
                <h6 class="mb-4" v-if="!verificado">Verificando su cuenta...espere</h6>
                <h6 class="mb-4" v-else>{{mensajeVerificado}}</h6>
                <router-link to="/login" v-if="verificado" class="btn btn-primary">Iniciar sesión</button></router-link>
            </div>
        </b-card>
    </b-colxx>
</b-row>
</template>

<script>

	export default {
	    data() {
	        return {
	            mensajeVerificado: "",
	            status: false
	        };
	    },
	    mounted(){
	    	this.verificacion();
	    },
	    methods: {
	       	verificacion: function(){
	            var instance = this;
	            var params =  new URLSearchParams(window.location.search);

	            //instance.$parent.toggleLoader("Verificando cuenta");
	            if(params.get("email") != null && params.get("tkn") != null){
	            	console.log(params);

		            axios.request({
		                method: "put",
		                url:"/api/usuario/verificar/" + params.get("email") + "/" + params.get("tkn") ,
		                //headers:{ Authorization: "Bearer " + token }
		            }).then(response => {
		                
		                if(response.data.status == 200){
		                    instance.mensajeVerificado = response.data.data;
		                    instance.verificado = true;
		                }else{
		                	instance.mensajeVerificado = response.data.mensaje;
		                }

		                //instance.$parent.toggleLoader();
		            });
		        }else{
		        	instance.mensajeVerificado = "Enlace inválido, favor de dar click en el enlace enviado a su correo electrónico";
		        }
	        }
	    },
	};
</script>